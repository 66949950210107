* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #459fbf;
  --background-color: #050f13;
}

a {
  text-decoration: none;
  color: #808080;
}

a:hover {
  color: #050f13;
}

#active {
  font-weight: 700 !important;
  color: black !important;
}

body {
  font-family: "Lato";
  overflow-y: hidden;
}

.docsLogo {
  width: 200px;
}

.header {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.navbar {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.content {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}
